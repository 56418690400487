import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, inject } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ActivatedRoute, Router } from '@angular/router';
import { IAutocompleteData } from '@components/shared/autocomplete/autocomplete';
import { AdvertType } from '@models/backend/advert';
import { IRegion } from '@models/backend/common';
import { IStreetNamesResponseBody } from '@models/backend/responses';
import { ITeaserFilter } from '@models/backend/teaser-filter';
import { ITeaserFilterOptions } from '@models/common/teaser-filter-options';
import { GoogleAnalyticsEvents } from '@models/google-analytics/google-analytics-events';
import { AdvertService } from '@services/advert.service';
import { AnalyticsService } from '@services/analytics.service';
import { CountryService } from '@services/country.service';
import { UserSettingsService } from '@services/user-settings.service';
import { timer } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';
import { UrlQueryParams } from './url-param-query';

type UnitType = `${AdvertType}`;

interface IAdvertType {
    key: string;
    value: UnitType;
}

@Component({
    selector: 'teaser-filter',
    templateUrl: 'teaser-filter.component.html',
    styleUrls: ['teaser-filter.component.less'],
})
export class TeaserFilterComponent extends UnSubscriptionDirective implements OnChanges, OnInit {
    private countryService = inject(CountryService);
    private userSettingsService = inject(UserSettingsService);
    private gaService = inject(AnalyticsService);
    private router = inject(Router);
    private activatedRoute = inject(ActivatedRoute);
    private advertService = inject(AdvertService);

    @Input()
    filterData: ITeaserFilter;

    @Input()
    showUnitListFilter;

    @Output()
    filterChanged = new EventEmitter<ITeaserFilterOptions | UrlQueryParams>();

    form: UntypedFormGroup;
    freeTextSearchForm: FormControl<string> = new FormControl();
    streetSearchForm: UntypedFormControl = new UntypedFormControl();
    unitCodeForm: UntypedFormControl = new UntypedFormControl();
    assignedContactForm = new UntypedFormControl();

    isFilterExpanded: boolean = false;

    isMobile = false;

    selectedFilterOptions: ITeaserFilterOptions | UrlQueryParams;

    private readonly searchInputDebounce = 300;

    unitType: IAdvertType[] = [];

    showUnitCodeSearchLoader: boolean = false;

    unitCodes: IAutocompleteData[] = [];

    isVacancyAndLettableDateEnabled: boolean = this.countryService.getCurrentCountry() === 'FR';

    get isDataAndFormReady(): boolean {
        return this.filterData.contacts.length && this.form !== undefined;
    }

    get branches(): { name: string }[] {
        const region = this.filterData.regions.find((r) => r.name === this.selectedFilterOptions.region);
        return region ? region.branches : [];
    }

    private get selectedRegion(): IRegion | undefined {
        return this.filterData.regions.find((r) => r.name === this.selectedFilterOptions.region);
    }

    ngOnInit(): void {
        this.unitCodeSearch();
        this.getUnitType();
        this.isMobile = this.userSettingsService.isMobileDevice();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['showUnitListFilter']) {
            this.showUnitListFilter = changes['showUnitListFilter'].currentValue;
        }

        if (changes['filterData'] && !changes['filterData'].currentValue.contacts.length) {
            return;
        }

        // delete old filter
        const storedFilter = this.userSettingsService.getTeaserFilter();

        if (storedFilter?.assignedContactFilter?.hasOwnProperty('id')) {
            storedFilter.assignedContactFilter = null;
            this.userSettingsService.clearTeaserFilter();
        }

        this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
            // if url not contains query params
            if (Object.keys(params).length === 0) {
                this.selectedFilterOptions = this.userSettingsService.getTeaserFilter();
            } else {
                // query matching
                this.selectedFilterOptions = new UrlQueryParams({
                    ...params,
                    searchQuery: this.freeTextSearchForm.value,
                });
            }
        });

        if (
            this.selectedFilterOptions?.assignedContactFilter &&
            typeof this.selectedFilterOptions.assignedContactFilter === 'string'
        ) {
            const assignedContactFilter = this.findAssignedContact(this.selectedFilterOptions.assignedContactFilter);
            this.assignedContactForm.setValue(assignedContactFilter);
        }

        if (this.selectedFilterOptions?.streetName && typeof this.selectedFilterOptions.streetName === 'string') {
            this.streetSearchForm.setValue({
                id: this.selectedFilterOptions.streetName,
                label: this.selectedFilterOptions.streetName,
            });
        }

        if (this.selectedFilterOptions?.unitCode && typeof this.selectedFilterOptions.unitCode === 'string') {
            this.unitCodeForm.setValue({
                id: this.selectedFilterOptions.unitCode,
                label: this.selectedFilterOptions.unitCode,
            });
        }

        this.setDefaultRegionSelection();
        this.setDefaultBranchSelection();

        // initialize text search field
        this.freeTextSearchForm.setValue(this.selectedFilterOptions.searchQuery);

        // set default letting manager
        this.userSettingsService
            .getUserEmail()
            .pipe(distinctUntilChanged(), takeUntil(this.unsubscribe$))
            .subscribe((email) => {
                this.gotUserEmail(email);
            });

        this.updateQueryUrlParams();
    }

    onStreetSelection(id: string): void {
        this.selectedFilterOptions.streetName = id;
        this.streetSearchForm.setValue({
            id,
            label: id,
        });
        this.filterChanged.emit(this.selectedFilterOptions);
        this.updateQueryUrlParams();
    }

    onUnitCodeSelection(event: MatAutocompleteSelectedEvent): void {
        this.selectedFilterOptions.unitCode = event.option.value.id;
        this.filterChanged.emit(this.selectedFilterOptions);
        this.updateQueryUrlParams();
    }

    onOptionSelected(event: MatAutocompleteSelectedEvent): void {
        const contact = this.filterData.contacts.find((c) => c.id === event.option.value.id);
        this.assignedContactForm.setValue(contact);
        this.selectedFilterOptions.assignedContactFilter = contact;
        this.filterChanged.emit(this.selectedFilterOptions);
        this.updateQueryUrlParams();
    }

    onContactCleaned(): void {
        this.selectedFilterOptions.assignedContactFilter = null;
        this.filterChanged.emit(this.selectedFilterOptions);
        this.updateQueryUrlParams();
    }

    onUnitCodeSearchCleaned(): void {
        this.selectedFilterOptions.unitCode = null;
        this.filterChanged.emit(this.selectedFilterOptions);
        this.updateQueryUrlParams();
    }

    onStreetSearchCleaned(): void {
        this.selectedFilterOptions.streetName = null;
        this.filterChanged.emit(this.selectedFilterOptions);
        this.updateQueryUrlParams();
    }

    private unitCodeSearch(): void {
        this.unitCodeForm.valueChanges
            .pipe(
                tap((search) => {
                    if (search?.length < 1) {
                        this.unitCodes = null;
                        this.onUnitCodeSearchCleaned();
                    }
                }),
                filter((search) => {
                    return search !== null && search.length >= 1;
                }),
                debounceTime(500),
                tap(() => {
                    this.unitCodes = null;
                    this.showUnitCodeSearchLoader = true;
                }),
                switchMap((searchString: string) => {
                    return this.advertService.getUnitCodes(searchString).pipe(
                        finalize(() => {
                            this.showUnitCodeSearchLoader = false;
                        }),
                    );
                }),
                takeUntil(this.unsubscribe$),
            )
            .subscribe((response: IStreetNamesResponseBody) => {
                this.showUnitCodeSearchLoader = false;
                const unitCodes = response.data.map((item: string) => {
                    return {
                        id: item,
                        label: item,
                    };
                });
                this.unitCodes = unitCodes;
            });
    }

    resetFilter(): void {
        // reset autocomplete
        this.freeTextSearchForm.setValue('');
        this.streetSearchForm.setValue({ id: null, label: null });
        this.unitCodeForm.setValue({ id: null, label: null });
        this.assignedContactForm.setValue({ id: null, label: null });

        // emit new filter statre
        this.selectedFilterOptions = {
            ...this.selectedFilterOptions,
            streetName: null,
            unitCode: null,
            assignedContactFilter: null,
            searchQuery: '',
        };

        this.form.reset();
        this.form.get('sortBy').setValue('moveoutdate');
        this.form.get('sortDirection').setValue('ASC');

        this.userSettingsService.clearTeaserFilter();
        this.filterChanged.emit(this.selectedFilterOptions);
        this.updateQueryUrlParams();
    }

    private getUnitType(): void {
        const countryCode = this.countryService.getCurrentCountry();

        switch (countryCode) {
            case 'DE':
                this.unitType.push(
                    {
                        key: 'SHARED_COMPONENT.PURPOSE_LIVING',
                        value: 'living',
                    },
                    {
                        key: 'SHARED_COMPONENT.PURPOSE_APARTMENT_SALES',
                        value: 'apartmentForSale',
                    },
                    {
                        key: 'SHARED_COMPONENT.PURPOSE_COMMERCIAL',
                        value: 'commercial',
                    },
                    {
                        key: 'SHARED_COMPONENT.PURPOSE_PARKING',
                        value: 'parking',
                    },
                );
                break;
            case 'FR':
            case 'CY':
                this.unitType.push(
                    {
                        key: 'SHARED_COMPONENT.PURPOSE_LIVING',
                        value: 'living',
                    },
                    {
                        key: 'SHARED_COMPONENT.PURPOSE_COMMERCIAL',
                        value: 'commercial',
                    },
                );
                break;

            case 'GB':
                this.unitType.push({
                    key: 'SHARED_COMPONENT.PURPOSE_LIVING',
                    value: 'living',
                });
                break;

            case 'SE':
                this.unitType.push({
                    key: 'SHARED_COMPONENT.HUNTING_AREA',
                    value: 'huntingArea',
                });
                break;

            case 'US':
            case 'CA':
                this.unitType.push(
                    {
                        key: 'SHARED_COMPONENT.PURPOSE_LIVING',
                        value: 'living',
                    },
                    {
                        key: 'SHARED_COMPONENT.PURPOSE_PARKING',
                        value: 'parking',
                    },
                );
                break;
        }
    }

    clearUnitSearchForm(): void {
        this.selectedFilterOptions.searchQuery = '';
    }

    private findAssignedContact(email: string): IAutocompleteData {
        return this.filterData.contacts.find((c) => c.id === email);
    }

    private gotUserEmail(currentUserEmail: string): void {
        if (!this.selectedFilterOptions.assignedContactFilter) {
            const selectedContact = this.filterData.contacts.find((c) => c.id === currentUserEmail) || null;
            this.selectedFilterOptions.assignedContactFilter = selectedContact;
            this.assignedContactForm.setValue(selectedContact);
        }

        // initialize form
        this.form = new UntypedFormGroup({
            status: new UntypedFormControl(this.selectedFilterOptions.status),
            vacancyStatuses: new UntypedFormControl(this.selectedFilterOptions.vacancyStatuses),
            advertType: new UntypedFormControl(this.selectedFilterOptions.advertType),
            sortBy: new UntypedFormControl(this.selectedFilterOptions.sortBy),
            sortDirection: new UntypedFormControl(this.selectedFilterOptions.sortDirection),
            region: new UntypedFormControl(this.selectedFilterOptions.region),
            branch: new UntypedFormControl(this.selectedFilterOptions.branch),
        });

        this.form.valueChanges
            .pipe(distinctUntilChanged(), takeUntil(this.unsubscribe$))
            .subscribe((form) => this.filtersChanged(form));

        this.freeTextSearchForm.valueChanges
            .pipe(distinctUntilChanged(), debounceTime(this.searchInputDebounce), takeUntil(this.unsubscribe$))
            .subscribe((val) => {
                this.searchTermChanged(val);
            });

        this.assignedContactForm.valueChanges
            .pipe(distinctUntilChanged(), debounceTime(this.searchInputDebounce), takeUntil(this.unsubscribe$))
            .subscribe((val) => {
                if (!val) {
                    this.onContactCleaned();
                }
            });

        this.filterChanged.emit(this.selectedFilterOptions);

        this.updateQueryUrlParams();
    }

    private filtersChanged(form: ITeaserFilterOptions): void {
        this.selectedFilterOptions = { ...this.selectedFilterOptions, ...form };

        if (!this.isBranchInRegion(this.selectedFilterOptions.branch, this.selectedFilterOptions.region)) {
            this.selectedFilterOptions.branch = null;
            this.form.patchValue({ branch: null }, { emitEvent: false });
        }

        if (this.selectedFilterOptions.sortBy) {
            this.gaService.event(
                GoogleAnalyticsEvents.AdvertsSorted,
                'data',
                `sort_by:${this.selectedFilterOptions.sortBy}`,
            );
        }

        this.filterChanged.emit(this.selectedFilterOptions);

        this.updateQueryUrlParams();
    }

    private updateQueryUrlParams(): void {
        const newFilter = structuredClone(this.selectedFilterOptions);

        if (newFilter?.assignedContactFilter && this.assignedContactForm.value) {
            const contact = this.findAssignedContact(this.assignedContactForm.value.id);
            newFilter.assignedContactFilter = contact.id;
        }

        this.userSettingsService.saveTeaserFilter(newFilter);

        // we are not persisting search
        delete newFilter.searchQuery;

        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: newFilter,
            queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
    }

    private searchTermChanged(search: string): void {
        this.gaService.event(GoogleAnalyticsEvents.AdvertsSearched, 'data', `keyword:${search}`);
        this.selectedFilterOptions.searchQuery = search;

        timer(300)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.filterChanged.emit(this.selectedFilterOptions);
            });
    }

    private setDefaultRegionSelection(): void {
        // set the region if available in current country
        // if no region selected and only one exists - preselect first
        const isRegionAvailable = this.filterData.regions.some((r) => r.name === this.selectedFilterOptions.region);
        if (!isRegionAvailable) {
            this.selectedFilterOptions.region = null;
        }
    }

    private setDefaultBranchSelection(): void {
        // if the saved branch does not belong to the selected region, set it to null
        const hasBranch = this.isBranchInRegion(this.selectedFilterOptions.branch, this.selectedFilterOptions.region);
        if (!hasBranch) {
            this.selectedFilterOptions.branch = null;
        }
    }

    private isBranchInRegion(branchName: string, regionName: string): boolean {
        const region = this.filterData.regions.find((r) => r.name === regionName);
        if (!region) {
            return false;
        }

        return region.branches.some((b) => b.name === branchName);
    }

    byIndex(index: number): number {
        return index;
    }
}
