import { Component, OnInit, inject } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { cultureInfoHelper } from '@helpers/culture-info-helper';
import { SupportedCultureInfo } from '@models/backend/common';
import { IUserDataResponseBody } from '@models/backend/responses';
import {
    NUMBER_LOCALE_DE,
    NUMBER_LOCALE_FR,
    NUMBER_LOCALE_GB,
    NUMBER_LOCALE_SE,
    NUMBER_LOCALE_US,
} from '@models/common/country';
import { TranslateService } from '@ngx-translate/core';
import { BrowserWindowService } from '@services/browser-window.service';
import { UserSettingsService } from '@services/user-settings.service';
import Numeral, { NumeralJSLocale } from 'numeral';
import { takeUntil } from 'rxjs/operators';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';

@Component({
    selector: 'home',
    templateUrl: 'home.component.html',
    styleUrls: ['home.component.less'],
})
export class HomeComponent extends UnSubscriptionDirective implements OnInit {
    private userSettingsService = inject(UserSettingsService);
    private translateService = inject(TranslateService);
    private browserWindowService = inject(BrowserWindowService);
    // private notificationsService = inject(NotificationsService);
    private dateAdapter = inject<DateAdapter<SupportedCultureInfo>>(DateAdapter);
    // private sentryService = inject(SentryService);
    private registeredLocales: Set<string> = new Set();

    isSettingsLoaded: boolean = false;
    isJumpToTopVisible: boolean = false;

    ngOnInit(): void {
        this.userSettingsService
            .getUserSettings()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((res) => this.gotSettings(res));
        this.translateService.onLangChange
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => this.translationChanged());
    }

    private gotSettings(settingsBody: IUserDataResponseBody): void {
        this.registerLocales();

        const selectedCultureInfo = cultureInfoHelper(settingsBody.data.selectedCultureInfo);

        localStorage.setItem('countryCode', settingsBody.data.selectedCountry);
        localStorage.setItem('userCultureInfo', selectedCultureInfo);
        localStorage.setItem('bookmarks', JSON.stringify(settingsBody.data.bookmarks));
        localStorage.setItem('bookmarkedProspects', JSON.stringify(settingsBody.data.bookmarkedProspects));

        this.translateService.use(selectedCultureInfo);
        this.dateAdapter.setLocale(selectedCultureInfo);
        Numeral.locale(selectedCultureInfo);
        this.isSettingsLoaded = true;

        // this.notificationsService
        //     .initializeHub(settingsBody.data.signalRConnection)
        //     .pipe(
        //         retry({
        //             delay: (error, count) => {
        //                 const retryAttempt = count + 1;
        //                 const scalingDurationInMilliseconds = 5000;
        //                 const scalingDuration = retryAttempt * scalingDurationInMilliseconds;

        //                 if (error && error.statusCode > 400) {
        //                     this.sentryService.captureMessage(
        //                         'notification service error: ' + error.statusCode,
        //                         'error',
        //                     );
        //                 }
        //                 return timer(scalingDuration);
        //             },
        //         }),
        //         takeUntil(this.unsubscribe$),
        //     )
        //     .subscribe();
    }

    private translationChanged(): void {
        Numeral.locale(this.translateService.currentLang);
    }

    private registerLocales(): void {
        this.registerLocale('de-DE', NUMBER_LOCALE_DE);
        this.registerLocale('fr-FR', NUMBER_LOCALE_FR);
        this.registerLocale('sv-SE', NUMBER_LOCALE_SE);
        this.registerLocale('en-GB', NUMBER_LOCALE_GB);
        this.registerLocale('en-US', NUMBER_LOCALE_US);
    }

    private registerLocale(locale: string, localeData: NumeralJSLocale): void {
        if (!this.registeredLocales.has(locale)) {
            Numeral.register('locale', locale, localeData);
            this.registeredLocales.add(locale);
        }
    }

    jumpToTop(): void {
        this.browserWindowService.scrollTo(0, 0);
    }
}
