<div class="option-modal" [ngClass]="{ 'status-update-dialog let-mat-dialog full': isAfterEmail }">
    <section class="head">
        <article class="subtitle">
            @if (isAfterEmail) {
                {{ 'SHARED_COMPONENT.NEW_EMAIL' | translate }}
            } @else {
                {{ 'SHARED_COMPONENT.SET_STATUS' | translate }}
            }
        </article>
        <button mat-icon-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </section>

    <section class="options">
        @if (isAfterEmail) {
            <ak-message-box [type]="'info'">
                {{ 'SHARED_COMPONENT.OUTLOOK_STARTING' | translate }}
            </ak-message-box>
            <article class="heading">{{ 'SHARED_COMPONENT.CHANGE_STATUS' | translate }}</article>
        }
        <mat-form-field>
            <mat-label>{{ 'SHARED_COMPONENT.STATUS' | translate }}</mat-label>
            <mat-select [(ngModel)]="selectedStatus">
                <mat-option *ngFor="let item of options; trackBy: byIndex" [value]="item.context">{{
                    item.name | translate
                }}</mat-option>
            </mat-select>
        </mat-form-field>
    </section>

    <section class="actions">
        <button mat-flat-button mat-dialog-close>
            {{ 'SHARED_COMPONENT.CANCEL' | translate }}
        </button>
        <button mat-flat-button color="primary" clickAndKeydown (actionTriggered)="save()">
            @if (isAfterEmail) {
                {{ 'SHARED_COMPONENT.OK' | translate }}
            } @else {
                {{ 'SHARED_COMPONENT.SUBMIT' | translate }}
            }
        </button>
    </section>
</div>
