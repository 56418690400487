import { Number } from '@helpers/number.helper';
import { AdvertType, IAdvert, IAdvertPublicationResult } from '@models/backend/advert';
import { IKeyfactLibrary } from '@models/backend/keyfacts';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Observable } from 'rxjs';
import { ILocalization, SupportedCountryCode, VacancyStatus } from '../backend/common';
import { getSectionKeyfacts, getValueOrUndefined } from './advert-keyfact-helper';
import { buildAdvertisementSection } from './builders/advertisement-section-builder';
import { buildPostalCodeAndCity, buildStreetNameAndHouseNumber } from './builders/postal-code-and-city-builder';
import { IContact } from './contact';
import { ImageViewModel } from './image';
import { getPhoneNumberFromCombinedString } from './phone-number';

export class AdvertViewModel {
    id: string;
    internalId: string;
    costCenterCode: string; // 4010
    unitCode: string; // 0001
    unitName: string;
    type: AdvertType;
    isPublished: boolean;
    lastPublishedDate: Date;
    lastUnpublishedReason: string;
    moveOutDate: Date;
    lettableDate: Date;

    streetName: string;
    houseNumber: string;
    // remove other once view api is implemented
    postalCodeAndCity: string;
    streetNameAndHouseNumber: string;
    ////////////////////////////////////////////
    direction: string;
    postalCode: string;
    city: string;
    district: string;
    countryCode: SupportedCountryCode;
    region: string | undefined;

    comment: string;
    virtualTourUrl: string;

    size: number;
    marketingSize: number;

    contractBaseRent: number | undefined;
    baseRent: number | undefined;
    totalRent: number | undefined;
    cost: number | undefined;
    additionalRent: number | undefined;

    numberOfRooms: number;
    marketingNumberOfRooms: number;

    numberOfBedrooms: number;
    marketingNumberOfBedrooms: number;

    numberOfBathrooms: number;
    marketingNumberOfBathrooms: number;

    isAvailableFromNowOn: boolean | undefined;
    isAvailabeUponAgreement: boolean | undefined;
    availabeFrom: Date | undefined;

    mainContact: IContact;
    substituteContact: IContact;

    images: ImageViewModel[];
    localization: ILocalization;

    numberOfNewProspects: number;
    numberOfProspectMessages: number;
    numberOfUpcomingViewings: number;
    numberOfViewingsWithConfirmedAttendees: number;
    numberOfOpenViewings: number;
    publicationResults: IAdvertPublicationResult[];

    keyfacts: IKeyfactLibrary;
    isReserved: boolean;
    lastReservationDate: Date;
    useServiceCenterPhoneNumber: boolean;
    isPhoneNumberShownOnPortal: boolean;
    prospectLimit: number | null;
    vacancyStatus: VacancyStatus | null;

    static factory(advert: IAdvert): AdvertViewModel {
        const vm = new AdvertViewModel();

        vm.keyfacts = advert.keyfacts;
        vm.id = advert.id;
        vm.internalId = advert.internalId;
        vm.costCenterCode = advert.costCenterCode;
        vm.unitCode = advert.unitCode;
        vm.type = advert.type;
        vm.isPublished = advert.isPublished;
        vm.lastPublishedDate = advert.lastPublishedDate ? new Date(advert.lastPublishedDate) : undefined;
        vm.lastUnpublishedReason = advert.lastUnpublishedReason;
        vm.moveOutDate = advert?.contract?.moveOutDate ? new Date(advert.contract.moveOutDate) : undefined;
        vm.lettableDate = advert?.lettableDate ? new Date(advert.lettableDate) : undefined;
        vm.direction = advert.direction;
        vm.localization = advert.localization;
        vm.comment = advert.comment;
        vm.virtualTourUrl = advert.virtualTourUrl;
        vm.isReserved = advert.isReserved;
        vm.lastReservationDate = advert.lastReservationDate ? new Date(advert.lastReservationDate) : undefined;
        vm.useServiceCenterPhoneNumber = advert.useServiceCenterPhoneNumber || false;
        vm.isPhoneNumberShownOnPortal = advert.isPhoneNumberShownOnPortal || false;

        vm.numberOfNewProspects = advert.numberOfNewProspects;

        vm.numberOfProspectMessages = advert.numberOfProspects;
        vm.numberOfUpcomingViewings = advert.numberOfUpcomingViewings;
        vm.numberOfViewingsWithConfirmedAttendees = advert.numberOfViewingsWithConfirmedAttendees;
        vm.numberOfOpenViewings = advert.numberOfOpenViewings;
        vm.publicationResults = advert.lastPublicationResults || [];
        vm.prospectLimit = advert.prospectLimit;

        const builderResult = buildAdvertisementSection(advert.address);
        vm.streetName = builderResult.streetName;
        vm.houseNumber = builderResult.houseNumber;
        vm.postalCode = builderResult.postalCode;
        vm.city = builderResult.city;
        vm.district = builderResult.district;
        vm.region = builderResult.region;
        // remove other once view api is implemented
        vm.streetNameAndHouseNumber = buildStreetNameAndHouseNumber(advert.address, advert.direction);
        vm.postalCodeAndCity = buildPostalCodeAndCity(advert.countryCode, advert.address);
        vm.countryCode = builderResult.countryCode;
        vm.images = advert.documents.map((d) => ImageViewModel.factory(d));
        vm.vacancyStatus = advert.vacancyStatus;

        vm.buildContactsSection(advert);
        vm.mapRent(advert);
        vm.mapAdvertisementProperties(advert);

        if (vm.hasUnitSection) {
            vm.mapUnitProperties(advert);
        }

        return vm;
    }

    private getTranslatedMessage(
        translateService: TranslateService,
        message: string,
        days: number,
    ): Observable<string> {
        return translateService.get(message, { days });
    }

    private getTargetSize(): number {
        return Number.isPositiveNumberOrZero(this.marketingSize) ? this.marketingSize : this.size;
    }

    get isUnitedStatesUnit(): boolean {
        return this.isUnitedStatesApartment || this.isUnitedStatesParking;
    }

    get enableViewingsAndProspectsByCostCenterCode(): boolean {
        // this cost centers are pilot ones for prospect and viewing functionality as they do not have Real Page AI and MeetElise
        const washingtonCostCenter = ['5532', '5525', '5530', '5524'];
        return washingtonCostCenter.includes(this.costCenterCode);
    }

    get isGermanUnit(): boolean {
        return this.isGermanApartment || this.isGermanCommercial || this.isGermanParkingSpace || this.isGermanCondo;
    }

    get isFrenchUnit(): boolean {
        return this.isFrenchApartment || this.isFrenchCommercial;
    }

    get isBritishUnit(): boolean {
        return this.isBritishApartment || this.isBritishCommercial;
    }

    get isSwedishUnit(): boolean {
        return this.countryCode === 'SE';
    }

    get isCyprusUnit(): boolean {
        return this.countryCode === 'CY';
    }

    get isCanadianUnit(): boolean {
        return this.isCanadianApartment || this.isCanadianParkingSpace;
    }

    get isCanadianTorontoUnit(): boolean {
        return this.isCanadianUnit && this.region === 'Toronto';
    }

    get isUnitedStatesApartment(): boolean {
        return this.type === AdvertType.Living && this.countryCode === 'US';
    }

    get isUnitedStatesParking(): boolean {
        return this.type === AdvertType.Parking && this.countryCode === 'US';
    }

    get isGermanApartment(): boolean {
        return this.type === AdvertType.Living && this.countryCode === 'DE';
    }

    get isGermanCondo(): boolean {
        return this.type === AdvertType.ApartmentForSale && this.countryCode === 'DE';
    }

    get isBritishApartment(): boolean {
        return this.type === AdvertType.Living && this.countryCode === 'GB';
    }

    get isBritishCommercial(): boolean {
        return this.type === AdvertType.Commercial && this.countryCode === 'GB';
    }

    get isGermanCommercial(): boolean {
        return this.type === AdvertType.Commercial && this.countryCode === 'DE';
    }

    get isGermanParkingSpace(): boolean {
        return this.type === AdvertType.Parking && this.countryCode === 'DE';
    }

    get isFrenchApartment(): boolean {
        return this.type === AdvertType.Living && this.countryCode === 'FR';
    }

    get isFrenchCommercial(): boolean {
        return this.type === AdvertType.Commercial && this.countryCode === 'FR';
    }

    get isCanadianApartment(): boolean {
        return this.type === AdvertType.Living && this.countryCode === 'CA';
    }

    get isCanadianParkingSpace(): boolean {
        return this.type === AdvertType.Parking && this.countryCode === 'CA';
    }

    get hasNewProspectMessages(): boolean {
        return this.numberOfNewProspects > 0;
    }

    get hasUpcomingViewings(): boolean {
        return this.numberOfUpcomingViewings > 0;
    }

    get hasViewingsWithConfirmedAttendees(): boolean {
        return this.numberOfViewingsWithConfirmedAttendees > 0;
    }

    get hasProspectMessages(): boolean {
        return this.numberOfProspectMessages > 0;
    }

    get hasImages(): boolean {
        return this.images && this.images.length > 0;
    }

    get contactDisplay(): string | undefined {
        if (this.hasNoContactAssigned) {
            return undefined;
        }

        return `${this.mainContact.firstName} ${this.mainContact.lastName}`;
    }

    get areaSizeUnit(): string {
        return this.localization.areaUnit === 'sqm' ? 'm²' : 'ft²';
    }

    get hasRoomAndSizeLabel(): boolean {
        return this.type !== AdvertType.Parking;
    }

    get isMainContactValid(): boolean {
        return this.isContactSectionValidByCountry(this.mainContact, this.countryCode);
    }

    get isSubstituteContactValid(): boolean {
        return (
            this.hasSubstituteContact && this.isContactSectionValidByCountry(this.substituteContact, this.countryCode)
        );
    }

    get hasSubstituteContact(): boolean {
        return this.substituteContact !== undefined;
    }

    get hasNoContactAssigned(): boolean {
        return this.hasSubstituteContact ? this.substituteContact.email === '' : this.mainContact.email === '';
    }

    get daysSincePublished(): number {
        const lastPublished = new Date(this.lastPublishedDate);
        lastPublished.setHours(0);
        lastPublished.setMinutes(0);
        return moment().diff(lastPublished.getTime(), 'days');
    }

    get daysSinceReserved(): number {
        const lastReserved = new Date(this.lastReservationDate);
        lastReserved.setHours(0);
        lastReserved.setMinutes(0);
        return moment().diff(lastReserved.getTime(), 'days');
    }

    get publishedMessage(): string {
        switch (this.daysSincePublished) {
            case 0:
                return 'SHARED_COMPONENT.PUBLISHED_MESSAGE_TODAY';
            case 1:
                return 'SHARED_COMPONENT.PUBLISHED_MESSAGE_YESTERDAY';
            default:
                return 'SHARED_COMPONENT.PUBLISHED_MESSAGE';
        }
    }

    get reservedMessage(): string {
        switch (this.daysSinceReserved) {
            case 0:
                return 'SHARED_COMPONENT.RESERVED_MESSAGE_TODAY';
            case 1:
                return 'SHARED_COMPONENT.RESERVED_MESSAGE_YESTERDAY';
            default:
                return 'SHARED_COMPONENT.RESERVED_MESSAGE';
        }
    }

    get lastRent(): number | undefined {
        return this.contractBaseRent;
    }

    get lastRentPerSize(): number | undefined {
        const rent = this.contractBaseRent;
        const targetSize = this.getTargetSize();

        return targetSize > 0 ? rent / targetSize : undefined;
    }

    get newRent(): number | undefined {
        if (this.isFrenchApartment) {
            return this.baseRent + this.additionalRent;
        }

        return this.baseRent;
    }

    get newRentPerSize(): number | undefined {
        const rent = this.newRent;
        const targetSize = this.getTargetSize();

        return targetSize > 0 ? rent / targetSize : undefined;
    }

    get costPerSize(): number | undefined {
        const cost = this.cost;
        const targetSize = this.getTargetSize();

        return targetSize > 0 ? cost / targetSize : undefined;
    }

    get hasPublicationResults(): boolean {
        return this.publicationResults.length > 0;
    }

    get hasExpose(): boolean {
        return this.lastPublishedDate !== undefined && this.lastPublishedDate !== null;
    }

    get showNumberOfRooms(): boolean {
        return (
            Number.isPositiveNumberOrZero(this.numberOfRooms) &&
            !Number.isPositiveNumberOrZero(this.marketingNumberOfRooms)
        );
    }

    get showMarketingNumberOfRooms(): boolean {
        return Number.isPositiveNumberOrZero(this.marketingNumberOfRooms);
    }

    get showNumberOfBedrooms(): boolean {
        return (
            Number.isPositiveNumberOrZero(this.numberOfBedrooms) &&
            !Number.isPositiveNumberOrZero(this.marketingNumberOfBedrooms)
        );
    }

    get showMarketingNumberOfBedrooms(): boolean {
        return Number.isPositiveNumberOrZero(this.marketingNumberOfBedrooms);
    }

    get showUnitSize(): boolean {
        return Number.isPositiveNumberOrZero(this.size) && !Number.isPositiveNumberOrZero(this.marketingSize);
    }

    get showMarketingUnitSize(): boolean {
        return Number.isPositiveNumberOrZero(this.marketingSize);
    }

    get numberOfRoomsLabel(): string {
        return this.numberOfRooms === 1 ? 'SHARED_COMPONENT.ROOM' : 'SHARED_COMPONENT.ROOMS';
    }

    get numberOfBedroomsLabel(): string {
        return this.numberOfBedrooms === 1 ? 'SHARED_COMPONENT.BEDROOM' : 'SHARED_COMPONENT.BEDROOMS';
    }

    get canBePublishedToAkeliusWebsite(): boolean {
        return this.images.some((image) => image.pictureDetail !== 'promotion');
    }

    get canPublishParkingSpaceToAkeliusWebsite(): boolean {
        if (this.isGermanParkingSpace) {
            return true;
        }

        return this.type !== AdvertType.Parking;
    }

    get hasUnitSection(): boolean {
        return (
            this.type === AdvertType.Living ||
            this.type === AdvertType.Commercial ||
            this.type === AdvertType.ApartmentForSale
        );
    }

    get isManagedByMontrealOffice(): boolean {
        if (!this.region) {
            return false;
        }

        const regions = ['Montreal', 'Ottawa', 'Quebec City'];

        return regions.includes(this.region);
    }

    get isUnsupportedUsRegionForProspectsAndViewingsManagement(): boolean {
        const regions = ['Washington'];

        return regions.includes(this.region);
    }

    get isVacant(): boolean {
        return this.vacancyStatus === 'vacant';
    }

    get isNotice(): boolean {
        return this.vacancyStatus === 'notice';
    }

    get isOccupied(): boolean {
        return this.vacancyStatus === 'occupied';
    }

    buildContactsSection(advert: IAdvert): void {
        this.mainContact = {
            email: advert.assignedContactEmail || '',
            firstName: advert.assignedContactFirstName || '',
            lastName: advert.assignedContactLastName || '',
            phoneNumber: getPhoneNumberFromCombinedString(advert.assignedContactPhoneNumber),
        };

        if (!advert.contactDetails) {
            return;
        }

        const { contactFirstName, contactLastName, email, phoneNumber } = advert.contactDetails;

        if (!!email && email !== advert.assignedContactEmail) {
            this.substituteContact = {
                email: email || '',
                firstName: contactFirstName || '',
                lastName: contactLastName || '',
                phoneNumber: getPhoneNumberFromCombinedString(phoneNumber),
            };
        }
    }

    addSubstituteContact(): void {
        this.substituteContact = {
            email: '',
            firstName: '',
            lastName: '',
            phoneNumber: getPhoneNumberFromCombinedString(''),
        };
    }

    isContactSectionValidByCountry(contact: IContact, countryCode: SupportedCountryCode): boolean {
        if (!contact) {
            return false;
        }

        const { firstName, lastName, email, phoneNumber } = contact;

        switch (countryCode) {
            case 'GB':
            case 'CY':
                return firstName !== undefined && lastName !== undefined && email !== undefined;
            default:
                return (
                    firstName !== undefined &&
                    lastName !== undefined &&
                    email !== undefined &&
                    phoneNumber.phoneAreaCode !== '' &&
                    phoneNumber.mainNumber !== ''
                );
        }
    }

    mapUnitProperties(advert: IAdvert): void {
        const keyfacts = getSectionKeyfacts(advert.keyfacts, 'unit-section');

        this.size = getValueOrUndefined(keyfacts, 'unit-size');
        this.marketingSize = getValueOrUndefined(keyfacts, 'marketing-size');

        this.numberOfRooms = getValueOrUndefined(keyfacts, 'number-of-rooms');
        this.marketingNumberOfRooms = getValueOrUndefined(keyfacts, 'marketing-number-of-rooms');

        this.numberOfBedrooms = getValueOrUndefined(keyfacts, 'number-of-bedrooms');
        this.marketingNumberOfBedrooms = getValueOrUndefined(keyfacts, 'marketing-number-of-bedrooms');

        this.numberOfBathrooms = getValueOrUndefined(keyfacts, 'number-of-bathrooms');
        this.marketingNumberOfBathrooms = getValueOrUndefined(keyfacts, 'marketing-number-of-bathrooms');
    }

    mapRent(advert: IAdvert): void {
        const keyfacts = getSectionKeyfacts(advert.keyfacts, 'costs-section');

        this.baseRent = getValueOrUndefined(keyfacts, 'base-rent');
        this.additionalRent = getValueOrUndefined(keyfacts, 'additional-rent');
        this.totalRent = getValueOrUndefined(keyfacts, 'total-rent');
        this.cost = getValueOrUndefined(keyfacts, 'cost');

        this.contractBaseRent = advert?.contract?.baseRent;
    }

    mapAdvertisementProperties(advert: IAdvert): void {
        const keyfacts = getSectionKeyfacts(advert.keyfacts, 'advertisement-section');

        this.isAvailableFromNowOn = getValueOrUndefined(keyfacts, 'is-available-from-now-on');
        this.availabeFrom = getValueOrUndefined(keyfacts, 'available-from-date');
        this.isAvailabeUponAgreement = getValueOrUndefined(keyfacts, 'is-available-upon-agreement');
        this.unitName = getValueOrUndefined(keyfacts, 'unit-name');
    }

    thumbnailImageStyle(teaserImageUrl: string) {
        return {
            'background-image': `url("${teaserImageUrl}"), url("/assets/image/akelius.png")`,
            'background-size': 'cover',
            'background-position': 'center',
            'background-repeat': 'no-repeat',
        };
    }

    getTranslatedPublishedMessage(translateService: TranslateService): Observable<string> {
        return this.getTranslatedMessage(translateService, this.publishedMessage, this.daysSincePublished);
    }

    getTranslatedReservedMessage(translateService: TranslateService): Observable<string> {
        return this.getTranslatedMessage(translateService, this.reservedMessage, this.daysSinceReserved);
    }
}
