import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { IDashboardViewResponseBody, IGeneralInquiriesResponseBody } from '@models/backend/responses';
import { IDashboardOptions } from '@models/common/dashboard-filter';
import { DashboardService } from '@services/dashboard.service';
import { Feature, FeatureToggleService } from '@services/feature-toggle.service';
import { GeneralInquiryService } from '@services/general-inquiries.service';
import { UserSettingsService } from '@services/user-settings.service';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, filter, takeUntil } from 'rxjs/operators';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';
import { LoadingIndicatorComponent } from '../shared';
import { GeneralInquiriesComponent } from './sections/general-inquiries/general-inquiries.component';

type Services = {
    dashboard: Observable<IDashboardViewResponseBody>;
    generalInquiriesResponse?: Observable<IGeneralInquiriesResponseBody>;
};
@Component({
    selector: 'dashboard',
    templateUrl: 'dashboard.component.html',
    styleUrls: ['dashboard.component.less'],
})
export class DashboardComponent extends UnSubscriptionDirective implements OnInit {
    private dashboardService = inject(DashboardService);
    private router = inject(Router);
    private userSettingsService = inject(UserSettingsService);
    private generalInquiryService = inject(GeneralInquiryService);
    private featureToggleService = inject(FeatureToggleService);

    isDataAvailable: boolean = false;
    userName: string = '';
    generalInquiriesTotal;
    isGeneralInquiryActive: boolean;
    hasPublishedOrBookmarked: boolean;
    form: UntypedFormGroup;

    @ViewChild(LoadingIndicatorComponent, { static: true })
    private loadingIndicator: LoadingIndicatorComponent;

    async ngOnInit(): Promise<void> {
        this.loadingIndicator.show();

        const { sortBy, sortDirection } = this.userSettingsService.getDashboardFilter();

        this.form = new UntypedFormGroup({
            sortBy: new UntypedFormControl(sortBy),
            sortDirection: new UntypedFormControl(sortDirection),
        });

        const services: Services = {
            dashboard: this.dashboardService.getData(sortBy, sortDirection),
        };

        const isGeneralInquiryAvailable = this.featureToggleService.isActive(Feature.GeneralInquiry);
        if (isGeneralInquiryAvailable) {
            services.generalInquiriesResponse = this.generalInquiryService.getGeneralInquiriesView().pipe(
                catchError(() => {
                    return of(undefined);
                }),
            );
        }

        await this.getDashboardData(services);

        this.form.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.loadingIndicator.show();

            const formSortBy: IDashboardOptions['sortBy'] = this.form.get('sortBy')!.value;
            const formSortDirection: IDashboardOptions['sortDirection'] = this.form.get('sortDirection')!.value;

            this.userSettingsService.saveDashboardFilter({ sortBy: formSortBy, sortDirection: formSortDirection });

            this.dashboardService.getData(formSortBy, formSortDirection).subscribe((dashboard) => {
                this.gotDashboardData(dashboard);
            });
        });

        this.router.events
            .pipe(
                takeUntil(this.unsubscribe$),
                filter((event) => event instanceof NavigationEnd),
            )
            .subscribe(async () => {
                this.isGeneralInquiryActive = this.router.url.includes('general-inquiries');
                await this.getDashboardData(services);
            });
    }

    onActivate(component: GeneralInquiriesComponent) {
        if (component.itemCountChange) {
            component.itemCountChange.subscribe((count: number) => {
                this.generalInquiriesTotal = count;
            });
        }
    }

    private async getDashboardData(services: Services): Promise<void> {
        forkJoin(services)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                ({ dashboard, generalInquiriesResponse }) => {
                    this.gotDashboardData(dashboard, generalInquiriesResponse);
                },
                () => {
                    this.requestError();
                },
            );
    }

    private gotDashboardData(
        dashBoardResponse: IDashboardViewResponseBody,
        generalInquiryResponse?: IGeneralInquiriesResponseBody,
    ): void {
        this.loadingIndicator.hide();

        if (generalInquiryResponse) {
            this.generalInquiriesTotal = generalInquiryResponse.data.totalElements;
        }

        this.dashboardService.setDashboardItems({
            dashboardResponse: dashBoardResponse,
            generalInquiryResponse,
        });

        this.userSettingsService
            .getUserName()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((userName) => {
                this.userName = userName;
            });

        this.isDataAvailable = !!dashBoardResponse.data;
        this.hasPublishedOrBookmarked = this.hasPublishedOrBookmarkedAdverts(dashBoardResponse);
    }

    private requestError(): void {
        this.loadingIndicator.hide();
        this.router.navigate(['/404']);
    }

    private hasPublishedOrBookmarkedAdverts(dashBoardResponse: IDashboardViewResponseBody): boolean {
        const data = dashBoardResponse.data;
        return !!data.topPublishedAdverts.length || !!data.userBookmarkedAdverts.length;
    }
}
