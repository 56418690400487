import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AdvertViewModel } from '@models/advert/advert';
import { TranslateService } from '@ngx-translate/core';
import { AdvertService } from '@services/advert.service';
import { AdvertStoreService } from '@services/advert.store';
import { EnvironmentService } from '@services/environment.service';
import { Feature, FeatureToggleService } from '@services/feature-toggle.service';
import { UserSettingsService } from '@services/user-settings.service';
import { takeUntil } from 'rxjs';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';
import { DownloadExposeComponent, IMessageBoxArgs, MessageBoxComponent } from '../shared';
import { PublishedPortalsComponent } from './published-portals/published-portals.component';

@Component({
    selector: 'advert-details-header',
    templateUrl: 'advert-details-header.component.html',
    styleUrls: ['advert-details-header.component.less'],
})
export class AdvertDetailsHeaderComponent extends UnSubscriptionDirective implements OnInit {
    private userSettingsService = inject(UserSettingsService);
    private advertStore = inject(AdvertStoreService);
    private advertService = inject(AdvertService);
    private dialog = inject(MatDialog);
    private translateService = inject(TranslateService);
    private environmentService = inject(EnvironmentService);
    private featureToggleService = inject(FeatureToggleService);

    @Input() isPreview = false;
    @Output() advertConverted = new EventEmitter();

    advert: AdvertViewModel;
    commentForm: UntypedFormControl;
    isSaving: boolean = false;

    get detailsRoute(): string {
        return `/advert/${this.advert.id}`;
    }

    get historyRoute(): string {
        return `/advert/${this.advert.id}/history`;
    }

    get prospectsRoute(): string {
        return `/advert/${this.advert.id}/prospects`;
    }

    get viewingsRoute(): string {
        return `/advert/${this.advert.id}/viewings`;
    }

    get isAdvertBookmarked(): boolean {
        return this.userSettingsService.isAdvertBookmarked(this.advert.id);
    }

    get bookmarkTooltipMessage(): string {
        return this.isAdvertBookmarked ? 'SHARED_COMPONENT.REMOVE_BOOKMARK' : 'SHARED_COMPONENT.BOOKMARK';
    }

    get canConvertApartment(): boolean {
        return !this.advert.isPublished && (this.advert.isGermanApartment || this.advert.isGermanCondo);
    }

    get conversionTooltipMessage(): string {
        return this.advert.isGermanApartment
            ? 'SHARED_COMPONENT.CONVERT_TO_SALES'
            : 'SHARED_COMPONENT.CONVERT_TO_RENTAL';
    }

    get enableViewingFeature() {
        return this.advert.countryCode !== 'SE';
    }

    // TODO(LA-5482): remove getter and usage in html
    get isUsAdvertOnProdEnvironment(): boolean {
        return (
            this.environmentService.getEnvinronment().name === 'production' &&
            this.advert.isUnitedStatesUnit &&
            this.advert.isUnsupportedUsRegionForProspectsAndViewingsManagement &&
            !this.advert.enableViewingsAndProspectsByCostCenterCode
        );
    }

    thumbnailImageStyle() {
        const teaserImageUrl = this.environmentService
            .getEnvinronment()
            .documentServiceTeaserImageUrl.replace('{costCenterCode}', this.advert.costCenterCode);

        return this.advert.thumbnailImageStyle(teaserImageUrl);
    }

    ngOnInit(): void {
        this.advertStore.advertSubject.pipe(takeUntil(this.unsubscribe$)).subscribe((a) => this.gotAdvert(a));
    }

    private gotAdvert(advert: AdvertViewModel): void {
        this.advert = advert;
        this.commentForm = new UntypedFormControl(advert.comment, { updateOn: 'blur' });
        this.commentForm.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((val) => this.commentChanged(val));
    }

    private commentChanged(val: string): void {
        this.advert.comment = val;
        this.isSaving = true;
        this.advertService
            .patchAdvertFragment(this.advert.id, { comment: val })
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => (this.isSaving = false));
    }

    toggleBookmark(): void {
        if (this.isAdvertBookmarked) {
            this.userSettingsService.removeBookmark(this.advert.id);
        } else {
            this.userSettingsService.addBookmark(this.advert.id);
        }

        this.userSettingsService.updateBookmarks().pipe(takeUntil(this.unsubscribe$)).subscribe();
    }

    showPublishedPortals(): void {
        if (this.featureToggleService.isActive(Feature.DisplayPortalsOnStageForFrance)) {
            this.advert.publicationResults = [
                ...this.advert.publicationResults,
                {
                    portalName: 'immobilienscout24',
                    statusCode: 200,
                    isSuccess: true,
                },
                {
                    portalName: 'immowelt',
                    statusCode: 200,
                    isSuccess: true,
                },
            ];

            this.dialog.open(PublishedPortalsComponent, { data: this.advert, panelClass: 'unconstrained-dialog' });
            return;
        }

        this.dialog.open(PublishedPortalsComponent, { data: this.advert, panelClass: 'unconstrained-dialog' });
    }

    openExposeDialog(): void {
        this.dialog.open(DownloadExposeComponent, { data: this.advert, panelClass: 'unconstrained-dialog' });
    }

    convertApartment(): void {
        const header = this.advert.isGermanApartment
            ? 'SHARED_COMPONENT.CONVERT_TO_SALES'
            : 'SHARED_COMPONENT.CONVERT_TO_RENTAL';
        const text = this.advert.isGermanApartment
            ? 'SHARED_COMPONENT.CONVERT_TO_SALES_MESSAGE'
            : 'SHARED_COMPONENT.CONVERT_TO_RENTAL_MESSAGE';

        const args: IMessageBoxArgs = {
            translatedMessageText: this.translateService.instant(text, { advertId: this.advert.id }),
            translatedTitleText: this.translateService.instant(header),
        };

        const dialog = this.dialog.open(MessageBoxComponent, { data: args });
        dialog
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((isConfirmed: boolean) => {
                if (isConfirmed) {
                    this.advertConverted.emit();
                }
            });
    }
}
