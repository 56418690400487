import { Component, EventEmitter, Input, OnInit, Output, computed, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IProspectModalArgs } from '@components/advert-prospects/advert-prospects.component';
import { ProspectEditorComponent } from '@components/advert-prospects/prospect-editor/prospect-editor.component';
import { ProspectEditDialogResult } from '@components/advert-prospects/types';
import { IPublishModalArgs, IReservationModalArgs, PublishComponent, ReservationComponent } from '@components/shared';
import { generateReservationEvent } from '@helpers/google-analytics.helper';
import { AdvertViewModel } from '@models/advert/advert';
import { AdvertType, IAdvertPublicationResult } from '@models/backend/advert';
import { ProspectViewModel } from '@models/prospect';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from '@services/analytics.service';
import { BrowserWindowService } from '@services/browser-window.service';
import { EnvironmentService } from '@services/environment.service';
import { ProspectsService } from '@services/prospects.service';
import { SnackBarService } from '@services/snack-bar.service';
import { UserSettingsService } from '@services/user-settings.service';
import { takeUntil } from 'rxjs/operators';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';

@Component({
    selector: 'advert-status',
    templateUrl: './advert-status.component.html',
    styleUrls: ['./advert-status.component.less'],
})
export class AdvertStatusComponent extends UnSubscriptionDirective implements OnInit {
    private snackBarService = inject(SnackBarService);
    private userSettingsService = inject(UserSettingsService);
    private browserWindowService = inject(BrowserWindowService);
    private translateService = inject(TranslateService);
    private dialog = inject(MatDialog);
    private gaService = inject(AnalyticsService);
    private prospectsService = inject(ProspectsService);
    // private notificationsService = inject(NotificationsService);
    private environmentService = inject(EnvironmentService);

    @Input()
    adverts: AdvertViewModel[];

    @Input()
    isBookmarksMode: boolean = false;

    @Input()
    display: boolean = false;

    @Output()
    advertPublishedChanged = new EventEmitter<AdvertViewModel>();

    @Output()
    advertBookmarkChanged = new EventEmitter();

    @Output()
    advertReservationChanged = new EventEmitter();

    currentFilter: AdvertType = AdvertType.Living;
    isStandaloneDisplayMode: boolean = false;

    getTranslatedDaysSincePublished(advert: AdvertViewModel) {
        return computed(() => advert.getTranslatedPublishedMessage(this.translateService));
    }

    getTranslatedDaysSinceReserved(advert: AdvertViewModel) {
        return computed(() => advert.getTranslatedReservedMessage(this.translateService));
    }

    get isLiving(): boolean {
        return this.currentFilter === AdvertType.Living;
    }

    ngOnInit(): void {
        // this.notificationsService.notifications
        //     .pipe(
        //         filter((n) => n.type === 'newProspectsForAdvert'),
        //         takeUntil(this.unsubscribe$),
        //     )
        //     .subscribe((n) => this.gotNotification(n));
        this.isStandaloneDisplayMode = this.userSettingsService.isStandaloneDisplayMode();
    }

    removeBookmark(advert: AdvertViewModel): void {
        this.userSettingsService.removeBookmark(advert.id);

        this.userSettingsService
            .updateBookmarks()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.advertBookmarkChanged.emit(advert);
            });
    }

    createProspect(advertId: string): void {
        const args: IProspectModalArgs = {
            advertId: null,
            prospects: null,
            advertType: null,
            defaulProspecttStatus: null,
        };

        this.openEditProspectDialog(args, advertId);
    }

    openEditProspectDialog(args: IProspectModalArgs, advertId: string): void {
        const dialog = this.dialog.open(ProspectEditorComponent, { data: args, panelClass: 'unconstrained-dialog' });
        dialog
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((dialogResult: ProspectEditDialogResult) => {
                const { prospect } = dialogResult;
                this.handleProspectCreate(prospect, advertId);
            });
    }

    handleProspectCreate(prospect: ProspectViewModel, advertId: string): void {
        this.prospectsService
            .createProspect(advertId, prospect)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.snackBarService.showSnackbar('PROSPECTS.SNACK_BAR_MESSAGE.PROSPECT_CREATE_SUCCESS');
            });
    }

    byAdvertId(_index: number, advert: AdvertViewModel): string {
        return advert.id;
    }

    openInNewTab(advert: AdvertViewModel): void {
        const location = this.browserWindowService.getWindowObject().location;
        const url = `${location.origin}/advert/${advert.id}`;
        this.browserWindowService.openExternalLink(url, true);
    }

    unpublishAdvert(advert: AdvertViewModel): void {
        const args: IPublishModalArgs = {
            advert: advert,
            mode: 'unpublish',
        };

        const dialog = this.dialog.open(PublishComponent, { data: args, panelClass: 'unconstrained-dialog' });
        dialog
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((results: IAdvertPublicationResult[]) => {
                const isSuccess = results.length > 0 && results.every((r) => r.isSuccess);
                if (isSuccess) {
                    advert.isPublished = false;
                    advert.isReserved = false;
                    this.advertPublishedChanged.emit(advert);
                }
            });
    }

    toggleReservation(advert: AdvertViewModel): void {
        generateReservationEvent(advert, this.gaService);

        const args: IReservationModalArgs = {
            advertId: advert.id,
            isReserved: advert.isReserved,
        };

        const dialog = this.dialog.open(ReservationComponent, { data: args });
        dialog
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                advert.isReserved = !advert.isReserved;
                advert.lastReservationDate = new Date();
                this.advertReservationChanged.emit(advert);
            });
    }

    // TODO(LA-5482): remove method and usage in html
    isUsAdvertOnProdEnvironment(advert: AdvertViewModel): boolean {
        return (
            advert.isUnitedStatesUnit &&
            advert.isUnsupportedUsRegionForProspectsAndViewingsManagement &&
            this.environmentService.getEnvinronment().name === 'production'
        );
    }

    // private gotNotification(n: NotificationViewModel): void {
    //     const data = n.data as IProspectStatusChangedData;
    //     const advert = this.adverts.find((a) => a.id === data.advertId);
    //     if (advert) {
    //         advert.numberOfNewProspects = data.prospectsCount;
    //     }
    // }
}
