import { AdvertType } from '@models/backend/advert';
import { PublicationPortal, SupportedCountryCode } from '@models/backend/common';

export type PortalDefinition = {
    portal: PublicationPortal;
    countryCodes?: SupportedCountryCode[];
    advertTypes?: AdvertType[];
    regions?: string[];
};

export const PORTAL_DEFINITIONS: PortalDefinition[] = [
    {
        portal: 'akelius-web',
    },
    {
        portal: 'immobilienscout24',
        countryCodes: ['DE'],
    },
    {
        portal: 'immowelt',
        countryCodes: ['DE'],
    },
    {
        portal: 'kijiji',
        countryCodes: ['CA'],
    },
    {
        portal: 'rentsync',
        countryCodes: ['CA'],
        advertTypes: [AdvertType.Living],
    },
    {
        portal: 'apartments-com',
        countryCodes: ['CA'],
        regions: ['Toronto', 'Montreal', 'Ottawa', 'Quebec City'],
        advertTypes: [AdvertType.Living],
    },
    {
        portal: 'rightmove',
        countryCodes: ['GB'],
    },
    {
        portal: 'seloger',
        countryCodes: ['FR'],
    },
    {
        portal: 'zoopla',
        countryCodes: ['GB'],
    },
    {
        portal: 'streeteasy',
        countryCodes: ['US'],
        advertTypes: [AdvertType.Living],
        regions: ['New York'],
    },
];
