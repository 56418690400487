<div class="general-inquiry-view">
    @if (!isForbidden) {
        <section data-test="filter" class="filter" [formGroup]="searchAndFilter">
            <mat-form-field>
                <input
                    matInput
                    type="text"
                    formControlName="searchForm"
                    autocomplete="off"
                    highlightActiveFormControl
                />
                <mat-label>{{ 'SHARED_COMPONENT.SEARCH_FOR_A_NAME' | translate }}</mat-label>
                <mat-icon
                    class="searchIcon"
                    matSuffix
                    clickAndKeydown
                    (actionTriggered)="clearSearchForm()"
                    (onkeydown)="clearSearchForm()"
                    >{{ showCloseOrSearchIcon }}</mat-icon
                >
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'SHARED_COMPONENT.STATUS' | translate }}</mat-label>
                <mat-select formControlName="status" highlightActiveFormControl>
                    <mat-option *ngFor="let status of statuses" [value]="status.value">{{
                        status.label | translate
                    }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'SHARED_COMPONENT.REGION' | translate }}</mat-label>
                <mat-select formControlName="region" highlightActiveFormControl>
                    <mat-option [value]="null">{{ 'SHARED_COMPONENT.ALL' | translate }}</mat-option>
                    <mat-option *ngFor="let region of regions; trackBy: byIndex" [value]="region">{{
                        region
                    }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'SHARED_COMPONENT.PROFIT_CENTER' | translate }}</mat-label>
                <mat-select formControlName="costCenterCodes" multiple>
                    @for (costCenterCode of costCenterCodes; track costCenterCode) {
                        <mat-option [value]="costCenterCode">{{ costCenterCode }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
        </section>

        <article class="subtitle">{{ 'FILTER_HINTS.totalElements' | translate }} {{ totalGeneralInquiries }}</article>

        <div class="general-inquiry-list">
            @if (totalGeneralInquiries) {
                <section data-test="general-inquiry-item">
                    @for (generalInquiry of generalInquiries; let last = $last; track $index) {
                        <general-inquiry-item
                            [generalInquiry]="generalInquiry"
                            (emailSelected)="email($event)"
                            (editClicked)="edit($event)"
                            (deleteSelected)="delete($event)"
                            (statusChanged)="setStatus($event)"
                            (createSelected)="convertGeneralInquiryToProspect($event)"
                            infinityScrolling
                            (elementVisible)="onScroll(last)"
                            [isTargetElement]="last"
                        ></general-inquiry-item>
                    }
                </section>

                @if (!isLoading && !generalInquiries.length) {
                    <div class="empty">
                        <ak-message-box [type]="'info'">
                            {{ 'GENERAL_INQUIRIES_VIEW.NO_GENERAL_INQUIRIES_FOUND' | translate }}
                        </ak-message-box>
                    </div>
                }
            } @else {
                @if (!isLoading && !generalInquiries.length) {
                    <div class="empty">
                        <ak-message-box [type]="'info'">
                            {{ 'GENERAL_INQUIRIES_VIEW.NO_GENERAL_INQUIRIES_AVAILABLE' | translate }}
                        </ak-message-box>
                    </div>
                }
            }
        </div>
    }

    @if (isForbidden) {
        <div class="empty forbidden">
            <ak-message-box [type]="'warning'">
                {{ 'GENERAL_INQUIRIES_VIEW.NOT_ALLOWED' | translate }}
            </ak-message-box>
        </div>
    }
</div>

<loading-indicator></loading-indicator>
