import { Component, inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GeneralInquiryHelper } from '@helpers/general-inquiry.helper';
import { GeneralInquiryStatus } from '@models/backend/general-inquiry';
import { IDropdownItem } from '@models/common/dropdown-item';
import { CountryService } from '@services/country.service';
import { takeUntil } from 'rxjs';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';

@Component({
    selector: 'general-inquiry-status',
    templateUrl: 'general-inquiry-status.component.html',
    styleUrls: ['general-inquiry-status.component.less'],
})
export class GeneralInquiryStatusComponent extends UnSubscriptionDirective implements OnInit {
    private countryService = inject(CountryService);

    dialogRef = inject<MatDialogRef<GeneralInquiryStatusComponent>>(MatDialogRef);
    args = inject<{ isAfterEmail: boolean }>(MAT_DIALOG_DATA);

    isAfterEmail: boolean = false;

    options: IDropdownItem<GeneralInquiryStatus>[];
    selectedStatus: GeneralInquiryStatus;

    ngOnInit(): void {
        const country = this.countryService.getCurrentCountry();
        this.options = GeneralInquiryHelper.getGeneralInquiryStatusItems(country);
        this.isAfterEmail = this.args.isAfterEmail;
        if (this.isAfterEmail) {
            this.selectedStatus = this.options.find((s) => s.context === GeneralInquiryStatus.MessageAnswered).context;
        } else {
            this.selectedStatus = this.options[0].context;
        }
        this.dialogRef
            .backdropClick()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => this.dialogRef.close());
    }

    save(): void {
        this.dialogRef.close(this.selectedStatus);
    }

    byIndex(index: number): number {
        return index;
    }
}
