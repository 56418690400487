import { Component, EventEmitter, OnInit, Output, ViewChild, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { LoadingIndicatorComponent } from '@components/shared';
import { String } from '@helpers/string.helper';
import { SupportedCountryCode } from '@models/backend/common';
import { GeneralInquiryStatus, IGeneralInquiry } from '@models/backend/general-inquiry';
import { IGeneralInquiryPageResponseBody } from '@models/backend/responses';
import { GeneralInquiryViewModel } from '@models/general-inquiry';
import { TranslateService } from '@ngx-translate/core';
import { BrowserWindowService } from '@services/browser-window.service';
import { CountryService } from '@services/country.service';
import { DashboardItems, DashboardService } from '@services/dashboard.service';
import { GeneralInquiryService, GeneralInquiryUpdateURequestBody } from '@services/general-inquiries.service';
import { SnackBarService } from '@services/snack-bar.service';
import { EMPTY, catchError, debounceTime, takeUntil } from 'rxjs';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';
import { GeneralInquiryCopyComponent } from './general-inquiry-copy/general-inquiry-copy.component';
import { GeneralInquiryCopyModalArgs } from './general-inquiry-copy/types';
import { GeneralInquiryDeletionComponent } from './general-inquiry-deletion/general-inquiry-deletion.component';
import { GeneralInquiryEditorComponent } from './general-inquiry-editor/general-inquiry-editor.component';
import { GeneralInquiryStatusComponent } from './general-inquiry-status/general-inquiry-status.component';
import { GeneralInquiryEditDialogResult, IGeneralInquiryModalArgs } from './types';
import { compareByStatusAndDateOfReceipt } from './utils';

type AllowedRegionsForCA = ['Toronto', 'Montreal'];
type AllowedRegions = AllowedRegionsForCA;

type Filter = {
    searchForm: FormControl<string>;
    status: FormControl<GeneralInquiryStatus>;
    region: FormControl<AllowedRegions[number] | null>;
    costCenterCodes: FormControl<string[]>;
};

interface StatusFilter {
    label: string;
    value: string | null;
}

@Component({
    selector: 'general-inquiries',
    templateUrl: 'general-inquiries.component.html',
    styleUrls: ['general-inquiries.component.less'],
})
export class GeneralInquiriesComponent extends UnSubscriptionDirective implements OnInit {
    private dashboardService = inject(DashboardService);
    private translateService = inject(TranslateService);
    private generalInquiryService = inject(GeneralInquiryService);
    private countryService = inject(CountryService);
    private browserWindowService = inject(BrowserWindowService);
    private snackBarService = inject(SnackBarService);

    dialog = inject(MatDialog);

    generalInquiries: GeneralInquiryViewModel[] = [];
    totalGeneralInquiries = 0;
    isLoading = true;
    isForbidden = false;

    @Output() scrollEventTriggered = new EventEmitter<void>();

    statuses: StatusFilter[] = [
        { label: 'SHARED_COMPONENT.ALL', value: null },
        { label: 'GENERAL_INQUIRIES_VIEW.STATUS.new', value: 'new' },
        { label: 'GENERAL_INQUIRIES_VIEW.STATUS.messageAnswered', value: 'messageAnswered' },
    ];

    costCenterCodes: string[] = [];

    searchAndFilter: FormGroup<Filter>;
    regions: AllowedRegions;

    @Output() itemCountChange = new EventEmitter<number>();

    @ViewChild(LoadingIndicatorComponent, { static: true })
    private loadingIndicator: LoadingIndicatorComponent;

    page = 0;
    searchForm: string;
    status: GeneralInquiryStatus;
    region: AllowedRegions[number];
    selectedCostCenterCodes: string[] = [];
    lastCallWasEmpty: boolean = false;

    countryCode: SupportedCountryCode;

    get showCloseOrSearchIcon(): string {
        return this.searchAndFilter['controls'].searchForm.value ? 'close' : 'search';
    }

    ngOnInit(): void {
        this.loadingIndicator.show();

        this.countryCode = this.countryService.getCurrentCountry();
        this.dashboardService.dashboardData$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(({ generalInquiryResponse }: DashboardItems) => {
                this.loadingIndicator.hide();

                if (!generalInquiryResponse) {
                    this.isForbidden = !generalInquiryResponse;
                    return;
                }

                const { data, totalElements, costCenterCodes } = generalInquiryResponse.data;
                this.costCenterCodes = costCenterCodes;
                this.proccessGeneralInquiries(data, totalElements);
            });

        this.regions = this.getRegionsByCountry(this.countryCode);

        this.searchAndFilter = new FormGroup({
            searchForm: new FormControl<string>(''),
            status: new FormControl(null),
            region: new FormControl<AllowedRegions[number] | null>(null),
            costCenterCodes: new FormControl<string[]>([]),
        });

        this.searchAndFilter.valueChanges.pipe(debounceTime(500), takeUntil(this.unsubscribe$)).subscribe((val) => {
            this.generalInquiries = [];
            this.searchForm = val.searchForm;
            this.status = val.status;
            this.region = val.region;
            this.selectedCostCenterCodes = val.costCenterCodes;
            this.page = 0;
            this.lastCallWasEmpty = false;
            this.isLoading = true;
            this.callSearchAndFilter();
        });
    }

    private callSearchAndFilter(): void {
        this.loadingIndicator.show();

        this.generalInquiryService
            .getData(this.searchForm, this.status, this.region, this.selectedCostCenterCodes, this.page)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((res) => {
                this.loadingIndicator.hide();
                this.gotInquiryData(res);
            });
    }

    private gotInquiryData(res: IGeneralInquiryPageResponseBody) {
        const { data, totalElements } = res;

        if (!data.length) {
            this.lastCallWasEmpty = true;
        }

        this.proccessGeneralInquiries(data, totalElements);
    }

    private proccessGeneralInquiries(generalInquiries: IGeneralInquiry[], totalElements: number): void {
        this.totalGeneralInquiries = totalElements;
        this.itemCountChange.emit(totalElements);

        this.generalInquiries = [
            ...this.generalInquiries,
            ...generalInquiries.map((g) => GeneralInquiryViewModel.factory(g)),
        ];
        this.sortGeneralInquiries();
        this.isLoading = false;
    }

    email(generalInquiry: GeneralInquiryViewModel) {
        const subjectSegment = '';
        const emailAddressSegment = generalInquiry.email.replace('&', '%26');
        const link = `mailto:${emailAddressSegment}?subject=${subjectSegment}&body=${this.createBodySegment(generalInquiry)}`;

        this.browserWindowService.openExternalLink(link);

        const dialog = this.dialog.open(GeneralInquiryStatusComponent, { data: { isAfterEmail: true } });
        dialog
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((status: GeneralInquiryStatus | undefined) => {
                if (status) {
                    this.handleOptimisticStatusUpdate(generalInquiry, status);
                }
            });
    }

    edit(generalInquiry: GeneralInquiryViewModel) {
        const args: IGeneralInquiryModalArgs = {
            generalInquiry,
            status: generalInquiry.status,
        };
        this.openEditGeneralInquiryDialog(args);
    }

    delete(generalInquiry: GeneralInquiryViewModel) {
        const args: IGeneralInquiryModalArgs = {
            generalInquiry,
            status: generalInquiry.status,
            defaultStatus: GeneralInquiryStatus.New,
        };

        const dialog = this.dialog.open(GeneralInquiryDeletionComponent, { data: args });
        dialog
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((isSuccess) => {
                if (isSuccess) {
                    this.updateGeneralInquiries(generalInquiry);
                }
            });
    }

    setStatus(generalInquiry: GeneralInquiryViewModel) {
        const dialog = this.dialog.open(GeneralInquiryStatusComponent, { data: { isAfterEmail: false } });
        dialog
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((status: GeneralInquiryStatus | null) => {
                if (status) {
                    this.handleOptimisticStatusUpdate(generalInquiry, status);
                }
            });
    }

    onScroll(isLast: boolean): void {
        if (isLast && !this.lastCallWasEmpty) {
            this.page++;
            this.callSearchAndFilter();
        }
    }

    convertGeneralInquiryToProspect(generalInquiry: GeneralInquiryViewModel): void {
        const args: GeneralInquiryCopyModalArgs = {
            generalInquiry,
        };
        const dialog = this.dialog.open(GeneralInquiryCopyComponent, {
            data: args,
            panelClass: 'unconstrained-dialog',
        });
        dialog
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((generalInquiryId) => {
                if (generalInquiryId) {
                    this.updateGeneralInquiries(generalInquiry);
                }
            });
    }

    private createBodySegment(generalInquiry: GeneralInquiryViewModel): string {
        const useNameSubstitute = String.isEmpty(generalInquiry.name);

        const greeting = useNameSubstitute
            ? this.translateService.instant('PROSPECTS.GREETING')
            : this.translateService.instant('PROSPECTS.HELLO', { name: generalInquiry.name });

        const thankYou = this.translateService.instant('PROSPECTS.THANK_EN', { streetName: '' });

        return `${greeting}${thankYou}`.replace('&', '%26'); // when the text contains & it means for the url its a mail parameter and it will not generate the email link
    }

    private handleOptimisticStatusUpdate(generalInquiry: GeneralInquiryViewModel, status: GeneralInquiryStatus): void {
        const map = this.prepeareInquiriesMap();

        generalInquiry.status = status;

        this.sortGeneralInquiries();

        this.generalInquiryService
            .updateGeneralInquiryStatus(status, generalInquiry.id)
            .pipe(
                catchError(() => {
                    this.restoreOldInquiry(map);
                    this.snackBarService.showSnackbar('GENERAL_INQUIRIES_VIEW.SNACK_BAR_MESSAGE.ERROR', 9000);
                    return EMPTY;
                }),
                takeUntil(this.unsubscribe$),
            )
            .subscribe(() => {
                this.snackBarService.showSnackbar('GENERAL_INQUIRIES_VIEW.SNACK_BAR_MESSAGE.STATUS_UPDATE_SUCCESS');
            });
    }

    private openEditGeneralInquiryDialog(args: IGeneralInquiryModalArgs): void {
        const dialog = this.dialog.open(GeneralInquiryEditorComponent, {
            data: args,
            panelClass: 'unconstrained-dialog',
        });
        dialog
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(({ generalInquiry }: GeneralInquiryEditDialogResult) => {
                if (generalInquiry) {
                    this.handleOptimisticEditUpdate(generalInquiry);
                }
            });
    }

    private handleOptimisticEditUpdate(updatedGeneralInquiry: GeneralInquiryViewModel): void {
        const { id, name, email, phone, status, message } = updatedGeneralInquiry;

        const map = this.prepeareInquiriesMap();

        this.generalInquiries = this.generalInquiries.map((inquiry) =>
            inquiry.id === updatedGeneralInquiry.id ? updatedGeneralInquiry : inquiry,
        );

        const generalInquiryRequestBody: GeneralInquiryUpdateURequestBody = {
            name,
            email,
            phone,
            status,
            message,
        };

        this.sortGeneralInquiries();

        this.generalInquiryService
            .updateGeneralInquiry(id, generalInquiryRequestBody)
            .pipe(
                catchError(() => {
                    this.restoreOldInquiry(map);
                    this.snackBarService.showSnackbar('GENERAL_INQUIRIES_VIEW.SNACK_BAR_MESSAGE.ERROR', 9000);
                    return EMPTY;
                }),
                takeUntil(this.unsubscribe$),
            )
            .subscribe(() => {
                this.snackBarService.showSnackbar(
                    'GENERAL_INQUIRIES_VIEW.SNACK_BAR_MESSAGE.GENERAL_INQUIRY_UPDATE_SUCCESS',
                );
            });
    }

    private updateGeneralInquiries(generalInquiry: GeneralInquiryViewModel): void {
        const generalInquiriesCount = this.generalInquiries.length - 1;
        this.itemCountChange.emit(generalInquiriesCount);
        this.generalInquiries = this.generalInquiries.filter((p) => p.id !== generalInquiry.id);
    }

    private getRegionsByCountry(countryCode: SupportedCountryCode): AllowedRegions {
        switch (countryCode) {
            case 'CA':
            default:
                return ['Toronto', 'Montreal'];
        }
    }

    private prepeareInquiriesMap(): Map<string, GeneralInquiryViewModel> {
        const map = new Map<string, GeneralInquiryViewModel>();
        for (const generalInquiry of this.generalInquiries) {
            map.set(generalInquiry.id, generalInquiry);
        }

        return map;
    }

    private sortGeneralInquiries(): void {
        this.generalInquiries.sort(compareByStatusAndDateOfReceipt).reverse();
    }

    private restoreOldInquiry(oldStatusesMap: Map<string, GeneralInquiryViewModel>): void {
        this.generalInquiries = this.generalInquiries.map<GeneralInquiryViewModel>((generalInquiry) => {
            if (oldStatusesMap.has(generalInquiry.id)) {
                generalInquiry = oldStatusesMap.get(generalInquiry.id);
            }

            return generalInquiry;
        });

        this.sortGeneralInquiries();
    }

    clearSearchForm(): void {
        this.searchAndFilter['controls'].searchForm.setValue(null);
    }
}
